import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layouts"
import SEO from "../components/seo"

import { useTranslationContext } from "../context/TranslationContext"
import JournalGrid from "../components/new-ui/journal-grid"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: calc(${(props) => props.theme.navbar.height} + 2rem) 20px;

  .page-title {
    text-align: left;
    font-size: 24px;
    margin-bottom: 32px;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: calc(${(props) => props.theme.navbar.height} + 2rem) 0;

    .page-title {
      text-align: center;
      font-size: 46px;
      margin-bottom: 112px;
    }
  }
`

const canonicals = {
  ["en-US"]: "/journal/",
  ["de-DE"]: "/de/journal/",
}

const AllJournals = ({ data }) => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return (
    <Layout almostWhite>
      <SEO title={"The Journal"} canonicals={canonicals} />
      <Container>
        <div className="page-title">The Journal</div>
        <JournalGrid data={data} locale={"en-US"} />
      </Container>
    </Layout>
  )
}

export default AllJournals

export const query = graphql`
  {
    posts: allContentfulBlogPost(
      filter: {
        contentful_id: { ne: "agABfUOdyx9ZfH1Pz4Ays" }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          author
          date
          description
          slug
          createdAt
          isPortrait
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
